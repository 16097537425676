import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";

export default {
  data() {
    return {
      loadingSeminarModules: [],
    };
  },
  computed: {
    ...mapFields("seminarModule", {
      seminarModuleIds: "allIds",
      seminarModuleIdsCached: "cacheAllIds",
    }),
    ...mapGetters("seminarModule", {
      findSeminarModule: "find",
    }),
  },
  methods: {
    ...mapActions("seminarModule", {retrieveSeminarModule: "load"}),
    resolveSeminarModule(itemId, force = false) {
      if (force || (!this.isSeminarModuleLoaded(itemId) && !this.loadingSeminarModules.includes(itemId))) {
        this.loadingSeminarModules.push(itemId);
        this.retrieveSeminarModule(decodeURIComponent(itemId));
      }

      return this.findSeminarModule(itemId);
    },
    resolveSeminarModules(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }

      return itemIds.map((itemId) => {
        return this.resolveSeminarModule(itemId);
      }).filter(e => e);
    },
    refreshSeminarModules(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      itemIds.forEach(itemId => this.resolveSeminarModule(itemId, true));
    },
    getSeminarModuleItem(seminarModuleIri, type) {
      if (seminarModuleIri !== null) {
        let seminarModule = this.resolveSeminarModule(seminarModuleIri);
        if (seminarModule) {
          return seminarModule[type];
        }
      }
      return '';
    },
    isSeminarModuleLoaded(itemId) {
      return this.seminarModuleIdsCached.includes(itemId);
    },
    isSeminarModuleSetLoaded(itemIds) {
      return itemIds.every(itemId => this.isSeminarModuleLoaded(itemId));
    },
    resolveSeminarModuleAsync(itemId) {
      const poll = resolve => {
        if (this.isSeminarModuleLoaded((itemId))) {
          resolve(this.resolveSeminarModule(itemId));
        } else {
          this.resolveSeminarModule(itemId);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
    resolveSeminarModulesAsync(itemIds) {
      const poll = resolve => {
        if (this.isSeminarModuleSetLoaded((itemIds))) {
          resolve(this.resolveSeminarModules(itemIds));
        } else {
          this.resolveSeminarModule(itemIds);
          setTimeout(() => poll(resolve), 400);
        }
      }
      return new Promise(poll);
    },
  }
};
